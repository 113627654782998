import * as React from "react";
import StorybookUIRoot from "../../storybook";

import { RootTabScreenProps } from "../../types";

export default function StoryScreen({
  navigation,
}: RootTabScreenProps<"StoryScreen">) {
  console.log("MODE:", process.env.__DEV__);
  return <StorybookUIRoot />;
}
